// Legacy web components
import './alert';
import './collapsible-section/inline-collapsible-section';
import './icon/icon';
import './lesson-content-item/lesson-content-item';
import './loading-spinner/loading-spinner';
import './video/brightcove-embed';
import './video/jwplayer-embed';
import './video/kaltura-embed';
import './video/kollective-embed';
import './video/video';
import './video/vidyard-embed';
import './video/vimeo-embed';
import './video/wistia-embed';
import './video/youtube-embed';
import './video/synthesia-embed';

// Gooey, Vue-filled components
import 'vite/modulepreload-polyfill';
import { defineCustomElement } from 'vue';

import Tile from './catalog-tile/CatalogTile.ce.vue';
import ThemeProvider from './theme-provider/ThemeProvider.ce.vue';
import CatalogTilePlatformWrapper from './catalog-tile-platform-wrapper/CatalogTilePlatformWrapper.ce.vue';

customElements.define('sjwc-theme-provider', defineCustomElement(ThemeProvider));
customElements.define('sjwc-catalog-tile', defineCustomElement(Tile));
customElements.define('sjwc-catalog-tile-platform-wrapper', defineCustomElement(CatalogTilePlatformWrapper));
